var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadings,
          expression: "loadings"
        }
      ],
      staticClass: "farmer-credit-details",
      attrs: { "element-loading-text": _vm.loadingTxt }
    },
    [
      _c("base-info", {
        attrs: {
          isHide: true,
          isAssessOptions: _vm.isAssessOptions,
          openFraction: true,
          info: _vm.baseInfo,
          approval: true,
          rating: _vm.rating,
          black: _vm.black,
          creditRatingOptions: _vm.creditRatingOptions,
          creditRatingDis: _vm.creditRatingDis,
          isEdit: _vm.isEdit
        },
        on: {
          "update:isEdit": function($event) {
            _vm.isEdit = $event
          },
          "update:is-edit": function($event) {
            _vm.isEdit = $event
          },
          determineEdit: _vm.determineEdit
        }
      }),
      _vm.proDetail
        ? _c("div", { staticClass: "credit-mock" }, [
            _c("div", { staticClass: "mock-title" }, [_vm._v("信用评定")]),
            _c(
              "div",
              { staticClass: "mock-content" },
              [_c("score-table", { attrs: { info: _vm.proDetail } })],
              1
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "operate" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }